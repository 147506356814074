import React, { useEffect } from 'react';
import MainLayout from '../layouts/mainLayout';
import { OptimizedImage } from '../components/common/OptimizedImage';
import loadable from '@loadable/component';
import { fetchCurrentBasket, useAppDispatch } from 'state';

const HowSection = loadable(() => import('components/Home/HowSection'));
const FeaturesSection = loadable(() =>
  import('components/Home/FeaturesSection'),
);

export default ({ location }: { location: any }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Registering a user will create a new cart. Fetch that new cart here
    //  so Solve knows the correct basket ID for the order success page.
    dispatch(fetchCurrentBasket());
  });

  const { dogsName = 'Demo', order = {} } = location.state || {};

  return (
    <MainLayout withTitle>
      <div className="page-wrapper">
        <div className="hdr text-center">
          <h2 className="mgn-bot-30  pdd-top-50 text-center">
            Sit tight while we pack{' '}
            <span className="txt-capitalize">{dogsName}</span>'s taste tester
            <br />
            and send it your way!
          </h2>
          <p className="text-center">
            {`Thank you so much for purchasing $${
              order && order.total_incl_tax ? order.total_incl_tax : '1'
            } sample! You will soon receive
            your personalised`}
            <br />
            feeding plan email! To get you started, we're offering you 15% off
            your first box when
            <br />
            you're ready to join us.
          </p>
        </div>
        <HowSection isTester={true} />
        <FeaturesSection isTester={true} />
        <div className="section-item def-w-max mgn-bot-100">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="txt-wrap-sect pdd-rt-50">
                <h2 className="mgn-bot-30">
                  We need simple, healthy, sustainable pet food...
                </h2>
                <p className="para-txt mgn-bot-50">
                  Jack has always been our Furbaby. But when kids came along and
                  life got busier, we found ourselves constantly running out of
                  dog food. <br />
                  After a busy family morning, and a mad dash to the shop... we
                  decided we’d had enough... “why isn’t there a subscription pet
                  food service in New Zealand?”. So we decided to build it.
                  <br />
                  <br />
                  Then we opened our eyes! As we started to look at the pet food
                  market we realised its a huge global category dominated by
                  poor nutrition, imported brands, thick plastic packaging and
                  little regard for the planet. <br />
                  <br />
                  We set about, not just creating convenient, natural,
                  sustainable pet food, but leading a huge global industry
                  towards plastic free pet food.
                </p>
                <div className="para-bold">
                  <p>
                    Our mission is helping humans be the best pet parents we can
                    be. That means taking better care of our pets, our planet
                    and making it so easy you don’t even have to think about it.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="op-img-wrap">
                <OptimizedImage fileName="str_img_1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
